.erro-div {
    text-align: center;
    margin: auto;
}

.erro-div p {
    font-weight: 300;
    font-size: 17px;
    font-family: sans-serif;
    padding-bottom: 30px;
}

.erro-div .btn {
    cursor: pointer;
    color: #85287e;
    border-color: #85287e;
    padding: 10px 20px;
}

.erro-div .btn:hover {
    cursor: pointer;
    background-color: #85287e;
    font-weight: 400;
    color: #fff;
    padding: 10px 20px;
}

.icon {
    color: #85287e;
    font-size: 150px;
    animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (min-width: 600px) {
    .erro-div {
        text-align: center;
        margin: auto;
    }

    .erro-div p {
        font-weight: 300;
        font-size: 22px;
        font-family: sans-serif;
        padding-bottom: 30px;
    }

    .erro-div .btn {
        cursor: pointer;
        color: #85287e;
        border-color: #85287e;
        padding: 10px 20px;
    }

    .erro-div .btn:hover {
        cursor: pointer;
        background-color: #85287e;
        font-weight: 400;
        color: #fff;
        padding: 10px 20px;
    }

    .iconError {
        color: #85287e;
        font-size: 230px;
        animation: pulse 2s infinite ease-in-out;
    }
}