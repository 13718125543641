/* Calendar.css */
.calendarClose {
  background-color: #000;
  /* position: absolute; */
  /* width: '100%'; */
  /* height: '100%'; */
  /* z-index: -1; */
}

.calendar {
  /* position: relative; */
  font-family: 'Arial', sans-serif;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #85287e;
  color: #fff;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.weekdays {
  margin: 5px;
  display: flex;
  background-color: #f0f0f0;
}

.weekday {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-right: 1px solid #ddd;
}

.weekday:last-child {
  border-right: none;
}

.days {
  margin: 5px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 3px;
}

.day:hover {
  background-color: #f5f5f5;
}

.day.disabled {
  cursor: not-allowed;
  background-color: #f0f0f0;
}

.day.dayPassed {
  cursor: not-allowed;
  background-color: #fca294;
}

.marked {
  /* cursor: not-allowed; */
  background-color: #9e50d9;
}
.renovacao {
  /* cursor: not-allowed; */
  background-color: #85287d8a;
}
.licenciamento {
  /* cursor: not-allowed; */
  background-color: #f3d194;
}



/* Calendar.css */
/* Adicione este CSS para estilizar a tabela de horários e sua transição */

/* ... CSS anterior ... */

.time-table {
  padding: 5px;
  margin-top: 20px;
  background-color: #fff;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  /* max-height: 0; */
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.time-table h3 {
  margin-bottom: 10px;
  color: #67348d;
  text-align: center;
}

.time-table table {
  width: 100%;
  border-collapse: collapse;
}

.time-table th,
.time-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

/* .time-table-tbody td:first-child:hover {
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: medium;
} */


.time-table th {
  background-color: #f0f0f0;
}


.time-table.show {
  opacity: 1;
  /* max-height: 300px;  */
}


.tags {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.tags div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.tag-color1 {
  width: 20px;
  height: 20px;
  background-color: #85287d8a;
}

.tag-color2 {
  width: 20px;
  height: 20px;
  background-color: #f3d194;
}