.dropdown-menu{
    display: none;
  }
  .dropdown-menu{
   z-index: 999;
  }
  
  .dropdown:hover .dropdown-menu {
      display: block;
      /*background-color: #5f2565;*/
    }
    #tabelaAdmin{
      width: 70% !important;
      overflow: auto !important;
    }
   
    li>ul                 {
      transform: translatex(100%) scale(0)
   }
     li:hover>ul           { 
       transform: translatex(101%) scale(1) 
   }
     li > button svg       { 
       transform: rotate(-90deg) 
   }
     li:hover > button svg { 
       transform: rotate(-270deg) 
   }
   
    
     .group:hover .group-hover\:scale-100 { 
       transform: scale(1) 
   }
     .group:hover .group-hover\:-rotate-180 { 
       transform: rotate(180deg) 
   }
     .scale-0 { 
       transform: scale(0)
   }
     .min-w-32 { 
       min-width: 8rem
   }
  
   .selecte{
      border-radius: 6px;
      border: solid 1px #5d5d5daa;
      padding: 16px;
      background-color: transparent !important;
      color: #3c3c3c;
   }
  

   /* @import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css'); */
 