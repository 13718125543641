
@media (min-width: 910px) {
  .uploads-drawer {
    width: 900px;
    margin: auto;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .upload-inputs {
    width: 900px;
    margin: auto;
  }

  .txtFiels{
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  .upload-inputs .txtNome{
    width: 410px;
    margin-left: 20px;
  }
  .upload-inputs .txtTexto{
    width: 410px;
    margin-left: 40px;
  }

  .file-upload-form {
    width: 400px;
  }

}