/* Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }

  .loaderr {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #85287e;
    /* border-top: 8px solid #3498db; */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }

  
  .loaderShort {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #85287e;
    /* border-top: 8px solid #3498db; */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    /* margin-bottom: 10px; */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  