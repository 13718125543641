
  .file-upload-form {
    width: 100%;
    /* padding: 0.5rem */
  }
  
  .file-upload-input {
    display: none;
  }
  
  .ffile-upload-label {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    /* height: 210px; */
    height: 4rem;
    border: 2px dashed #d1d5db;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #85287e;

  }
  
  .file-upload-label:hover {
    background-color: #f3f4f6;
  }
  
  .file-upload-label.drag-active {
    border-color: #3b82f6;
  }
  
  .upload-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    /* background-color: #85287e; */
  }
  
  .upload-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 12px;
    /* color: #9ca3af; */
  }
  
  .upload-text > span {
    margin-bottom: 8px;
    font-size: 13px;
    color: #fff;
    /* text-align: center; */
  }

  .txt-name{
    font-size: 13px;
    text-align: center;
  }
  
  .selected-files {
    margin-top: 16px;
  }
  
  .file-list {
    list-style-type: none;
    padding: 0;
  }
  
  .file-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .file-preview {
    max-width: 300px;
    height: auto;
  }
  
  .file-info {
    display: flex;
    align-items: center;
  }
  
  .download-link {
    color: #3b82f6;
    margin-left: 16px;
    text-decoration: none;
  }
  
  .remove-button {
    color: #ef4444;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 8px;
  }

  @media (min-width: 500px) {
    .uploads-drawer{
        width: 90%;
        margin: auto;
        gap: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    .ffile-uploader-container {
        max-width: 100%;
        margin: auto;
      }

  }


  @media (min-width: 910px) {
    .uploads-drawer{
        width: 900px;
        margin: auto;
        gap: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    .file-uploader-container {
        max-width: 100%;
        margin: auto;
      }
    
  }

  .error-message{
    color: #ef4444;
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: 1px;
  }



.preview-image{
  max-width: 100%;
  max-height: 240px;
  object-fit: contain;
}
