.artigo-home-artigo {
  width: 100%;
  /* height: 100Vh; */
  background: #fff;
}

.artigo-home {
  width: 100%;
  margin: auto;
  margin-top: 90px;
}

.artigo-home h1 {
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

.artigo-home p {
text-align: center;
  font-weight: 300;
  font-size: 18px;
  padding: 2px;
}

.artigo-filtro-section {
  background: #BA54BD;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fullWidth{
  width: 100%;
}

.artigo-txtTema,
.artigo-txtTipo {
  width: 100%;
}

.artigo-txt {
  background: #fff;
  border-radius: 5px;
}

.artigo-anoTxt {
  width: 100%;
}

.escriTxt {
  width: 60%;
}

.artigo-pdf-article-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  margin-top: 30px;
  justify-content: center;
}

.artigo-pdf-article-card-card {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 80%;
  padding: 5px 16px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.artigo-pdf-article-card-card:hover {
  border: 1px solid #00205D;
  cursor: pointer;
}

.artigo-pdf-article-card-image {
  height: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  border-radius: 2px;
  overflow: hidden;
}

.artigo-pdf-article-card-image img {
  max-width: 100%;
  max-height: 100%;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background: #cccccc;
}

.artigo-pdf-article-card-info {
  width: 100%;
}

.artigo-pdf-article-card-date {
  font-weight: 300;
  color: rgb(77, 77, 77);
  margin-bottom: 8px;
}

.artigo-pdf-article-card-title {
  font-size: 1em;
  font-weight: bold;
  height: 83px;
  padding: 5px 0;
  overflow: hidden; 
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  text-overflow: ellipsis; 
  color: #333;
  margin-bottom: 12px;
}

.artigo-pdf-article-card-links {
  display: flex;
  align-self: flex-start;
  font-size: 14pt;
  gap: 15px;
  margin-top: auto;
}

.artigo-pdf-article-card-download,
.artigo-pdf-article-card-read-more {
  font-size: 0.85em;
  color: #0c4c8c;
  text-decoration: none;
}

.artigo-pdf-article-card-download:hover,
.artigo-pdf-article-card-read-more:hover {
  text-decoration: none;
  cursor: pointer;
  color: #5799dc;
}

.artigo-article-details {
  background: #fff;
  width: 100%;
  margin: auto;
  margin-top: 15vh;
}

.artigo-article-details-overview {
  font-weight: 400;
  color: rgb(47, 46, 46);
  margin-bottom: 8px;
  padding: 0 30px;
}

.artigo-article-details h3 {
  margin-top: 50px;
  font-size: 18pt;
  color: rgb(77, 77, 77);
  margin-bottom: 17px;
  padding: 0 30px;
}

.artigo-article-details-date {
  display: flex;
  gap: 10px;
  font-weight: 300;
  color: rgb(77, 77, 77);
  font-size: 15px;
  margin-bottom: 25px;
  padding: 0 20px;
}

.artigo-article-details-view {
  width: 100%;
  border-top: 1px solid #0c4c8c;
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  gap: 35px;
}

.img{
  width: 100%;
}

.artigo-pdf-article-card-image-detalhe {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 30px;
  border-radius: 2px;
  overflow: hidden;
}


.artigo-pdf-article-card-image-detalhe img {
  max-width: 100%;
  max-height: 100%;
}

.baixar-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: #0078D4;
  border-radius: 4px;
  color: #fff;
  border: 0;
  font-weight: 400;
  cursor: pointer;
}
.edit-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: #35d400;
  border-radius: 4px;
  color: #fff;
  border: 0;
  font-weight: 400;
  cursor: pointer;
  margin: 10px 0;
}
.delete-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: #ea0e33;
  border-radius: 4px;
  color: #fff;
  border: 0;
  font-weight: 400;
  cursor: pointer;
}

.artigo-article-details-allView{
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: justify;
  margin-top: 5px;
}

.artigo-article-details-allView h4{
  padding: 10px 0;
  font-weight: 600;
  font-size: 17pt;
}

.artigo-article-details-allView p{
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1px;
}

.artigo-article-details-allView-right{
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
}

.artigo-article-details-allView-right h5{
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0;
  text-transform: uppercase;
  color: #00205D;
}

.artigo-article-details-allView-right p{
  font-size: 14px;
  font-weight: 390;
  letter-spacing: 1px;
}

.loader-artigo {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid lightblue;
  border-right-color: orange;
  animation: l2 1s infinite linear;
}
@keyframes l2 {to{transform: rotate(1turn)}}

@media (min-width: 700px) {
  .artigo-article-details {
    background: #fff;
    width: 100%;
    margin: auto;
    margin-top: 15vh;
  }

  .artigo-article-details-overview {
    font-weight: 400;
    color: rgb(47, 46, 46);
    margin-bottom: 8px;
    padding: 0 30px;
  }

  .artigo-article-details h3 {
    margin-top: 50px;
    font-size: 18pt;
    color: rgb(77, 77, 77);
    margin-bottom: 17px;
    padding: 0 30px;
  }

  .artigo-article-details-date {
    display: flex;
    gap: 10px;
    font-weight: 300;
    color: rgb(77, 77, 77);
    font-size: 15px;
    margin-bottom: 25px;
    padding: 0 20px;
  }

  .artigo-article-details-view {
    width: 100%;
    border-top: 1px solid #0c4c8c;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    gap: 35px;
  }

  .img{
    width: 30%;
  }

  .artigo-pdf-article-card-image-detalhe {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 30px;
    border-radius: 2px;
    overflow: hidden;
  }
  

  .artigo-pdf-article-card-image-detalhe img {
    max-width: 100%;
    max-height: 100%;
  }

  .baixar-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #0078D4;
    border-radius: 4px;
    color: #fff;
    border: 0;
    font-weight: 400;
    cursor: pointer;
  }

  .edit-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #35d400;
    border-radius: 4px;
    color: #fff;
    border: 0;
    font-weight: 400;
    cursor: pointer;
    margin: 10px 0;
  }
  .delete-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #ea0e33;
    border-radius: 4px;
    color: #fff;
    border: 0;
    font-weight: 400;
    cursor: pointer;
  }

  .artigo-article-details-allView{
    display: flex;
    flex-direction: column;
    width: 60%;
    text-align: justify;
    margin-top: 30px;
  }

  .artigo-article-details-allView h4{
    padding: 10px 0;
    font-weight: 600;
    font-size: 17pt;
  }

  .artigo-article-details-allView p{
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .artigo-article-details-allView-right{
    margin-top: 35px;
    width: 30%;
  }

  .artigo-article-details-allView-right h5{
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
    text-transform: uppercase;
    color: #00205D;
  }

  .artigo-article-details-allView-right p{
    font-size: 14px;
    font-weight: 390;
    letter-spacing: 1px;
  }

  .artigo-home-artigo{
    width: 100%;
    /* height: 100Vh; */
    background: #fff;
  }

  .artigo-home {
    width: 100%;
    margin: auto;
    margin-top: 90px;
  }

  .artigo-filtro-section {
    background: #BA54BD;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .fullWidth{
    width: 100%;
  }

  .artigo-txtTema,
  .artigo-txtTipo {
    width: 49%;
  }

  .artigo-txt {
    background: #fff;
  }

 

  .artigo-anoTxt {
    width: 21%;
  }

  .escriTxt {
    width: 49%;
  }

  .artigo-pdf-article-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-top: 30px;
    justify-content: center;
  }

  .artigo-pdf-article-card-card {
    background: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 260px;
    padding: 0 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .artigo-pdf-article-card-card:hover {
    border: 1px solid #00205D;
    cursor: pointer;
  }

  .artigo-pdf-article-card-image {
    width: 255px;
    height: 265px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    margin-bottom: 12px;
    border-radius: 2px;
    overflow: hidden;
  }

  .artigo-pdf-article-card-image img {
    width: 100%;
    height: 100%;
  }

  .placeholder-image {
    width: 100%;
    height: 100%;
    background: #cccccc;
  }

  .artigo-pdf-article-card-info {
    width: 100%;
  }

  .artigo-pdf-article-card-date {
    font-weight: 300;
    color: rgb(77, 77, 77);
    margin-bottom: 8px;
  }

  .artigo-pdf-article-card-download,
  .artigo-pdf-article-card-read-more {
    font-size: 0.85em;
    color: #0c4c8c;
    text-decoration: none;
  }

  .artigo-pdf-article-card-download:hover,
  .artigo-pdf-article-card-read-more:hover {
    text-decoration: none;
   
  }

  .artigo-autor{
    width: 26%;
  }
  
}

@media (min-width: 900px) {
  .artigo-home-artigo {
    width: 100%;
    /* height: 100Vh; */
    background: #fff;
  }

  .artigo-pais{
    max-width: 300px;
  }

  .artigo-home {
    width: 1150px;
    margin: auto;
    margin-top: 90px;
  }

  .artigo-filtro-section {
    background: #BA54BD;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .fullWidth{
    width: 100%;
  }

  .artigo-txtTema,
  .artigo-txtTipo {
    width: 49.5%;
  }

  .artigo-txt {
    background: #fff;
  }

  .artigo-anoTxt {
    width: 21%;
  }

  .escriTxt {
    width: 49%;
  }

  .artigo-pdf-article-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-top: 30px;
    justify-content: flex-start;
  }

  .artigo-pdf-article-card-card {
    background: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 260px;
    padding: 0 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .artigo-pdf-article-card-card:hover {
    border: 1px solid #00205D;
    cursor: pointer;
  }

  .placeholder-image {
    width: 100%;
    height: 100%;
    background: #cccccc;
  }

  .artigo-pdf-article-card-info {
    width: 100%;
  }

  .artigo-pdf-article-card-date {
    font-weight: 300;
    color: rgb(77, 77, 77);
    margin-bottom: 8px;
  }

  .artigo-pdf-article-card-download,
  .artigo-pdf-article-card-read-more {
    font-size: 0.85em;
    color: #0c4c8c;
    text-decoration: none;
  }

  .artigo-pdf-article-card-download:hover,
  .artigo-pdf-article-card-read-more:hover {
    text-decoration: none;
  }

}


@media (min-width:1000px) {
  .artigo-article-details {
    background: #fff;
    width: 1250px;
    margin: auto;
    margin-top: 15vh;
  }

  .artigo-article-details-overview {
    font-weight: 400;
    color: rgb(47, 46, 46);
    margin-bottom: 8px;
    padding: 0 30px;
  }

  .artigo-article-details h3 {
    margin-top: 50px;
    font-size: 18pt;
    color: rgb(77, 77, 77);
    margin-bottom: 17px;
    padding: 0 30px;
  }

  .artigo-article-details-date {
    display: flex;
    gap: 10px;
    font-weight: 300;
    color: rgb(77, 77, 77);
    font-size: 15px;
    margin-bottom: 25px;
    padding: 0 20px;
  }

  .artigo-article-details-view {
    width: 100%;
    border-top: 1px solid #0c4c8c;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    gap: 35px;
  }

  .img{
    width: 20%;
  }

  .artigo-pdf-article-card-image-detalhe {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 30px;
    border-radius: 2px;
    overflow: hidden;
  }
  

  .artigo-pdf-article-card-image-detalhe img {
    max-width: 100%;
    max-height: 100%;
  }

  .baixar-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #0078D4;
    border-radius: 4px;
    color: #fff;
    border: 0;
    font-weight: 400;
    cursor: pointer;
  }

  .artigo-article-details-allView{
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: justify;
    margin-top: 30px;
  }

  .artigo-article-details-allView h4{
    padding: 10px 0;
    font-weight: 600;
    font-size: 17pt;
  }

  .artigo-article-details-allView p{
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .artigo-article-details-allView-right{
    margin-top: 35px;
    width: 20%;
  }

  .artigo-article-details-allView-right h5{
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
    text-transform: uppercase;
    color: #00205D;
  }

  .artigo-article-details-allView-right p{
    font-size: 14px;
    font-weight: 390;
    letter-spacing: 1px;
  }

}