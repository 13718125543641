.form-home {
    width: 100%;
}

.box-form {
    width: 100%;
    padding: 5px;
}

.txt-form {
    width: 100%;
    margin: 5px;
}
.txt-form-drag {
    width: 100%;
    margin: 5px;
}

.txt-form-drag  .file-upload-form{
    width: 100%;
}

.form-home h1 {
    font-weight: 390;
    padding: 20px 0 20px 5px;
    font-size: 24px;
    letter-spacing: 2px;
    padding: 50px 10px;
    padding-bottom: 10px;
}

.artigo-upload {
    display: flex;
    flex-direction: column;
}

.file-uploader-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.file-upload-form {
    width: 100%;
}

.file-upload-input {
    display: none;
}

.file-upload-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border: 2px dashed #d1d5db;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-upload-label:hover {
    background-color: #f3f4f6;
}

.file-upload-label.drag-active {
    border-color: #3b82f6;
}

.upload-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.upload-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 12px;
    color: #9ca3af;
}

.upload-img {
    width: 150px;
    height: 100px;
    /* margin-bottom: 12px; */
    color: #9ca3af;
}


.upload-text {
    margin-bottom: 2px;
    padding: 0 10px;
    font-size: 16px;
    color: #6b7280;
    text-align: center;
}

.txt-name {
    font-size: 17px;
    text-align: center;
}

.selected-files {
    margin-top: 16px;
}

.file-list {
    list-style-type: none;
    padding: 0;
}

.file-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}

.file-preview {
    max-width: 300px;
    height: auto;
}

.file-info {
    display: flex;
    align-items: center;
}

.download-link {
    color: #3b82f6;
    margin-left: 16px;
    text-decoration: none;
}

.remove-button {
    color: #ef4444;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 8px;
}

.txtFiels {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.upload-inputs .txtNome {
    width: 100%;
    margin-top: 10px;
}

.upload-inputs .txtTexto {
    width: 100%;
    margin-top: 10px;
}

.error-message {
    color: #ef4444;
    margin-top: 20px;
    font-size: 16px;
    letter-spacing: 1px;
}

.preview-image {
    max-width: 100%;
    max-height: 190px;
    object-fit: contain;
}


.loader {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 3px;
    background: 
      radial-gradient(farthest-side,#ffa516 95%,#0000) 50% 0/12px 12px no-repeat,
      radial-gradient(farthest-side,#0000 calc(100% - 5px),#ffa516 calc(100% - 4px)) content-box;
    animation: l6 2s infinite ;
  }
  @keyframes l6 {to{transform: rotate(1turn)}}
  

@media (min-width: 650px) {
    .form-home {
        width: 80%;
        margin: auto;
        padding: 10px;
        margin-top: 80px;
    }

    .form-home h1 {
        font-weight: 390;
        padding: 20px 0 20px 5px;
        font-size: 24px;
        letter-spacing: 2px;
    }

    .box-form {
        margin-top: 25px;
    }

    .txt-form {
        width: 32%;
        margin: 5px;
    }
    .txt-form-drag {
        width: 32%;
        margin: 5px;
    }

    .file-uploader-container {
        width: 32%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }

    .uploads-drawer {
        width: 90%;
        margin: auto;
        gap: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    .upload-inputs {
        width: 90%;
        margin: auto;
        gap: 10px;
    }

    .upload-inputs .txtNome {
        width: 410px;
        margin: auto;
        margin-top: 10px;
    }

    .upload-inputs .txtTexto {
        width: 410px;
        margin: auto;
        margin-top: 10px;
    }

}

@media (min-width: 700px) {
    .form-home {
        width: 80%;
        margin: auto;
        padding: 10px;
        margin-top: 80px;
    }

    .form-home h1 {
        font-weight: 390;
        padding: 20px 0 20px 5px;
        font-size: 24px;
        letter-spacing: 2px;
    }

    .box-form {
        margin-top: 25px;
    }

    .txt-form {
        width: 45%;
        margin: 5px;
    }
    .txt-form-drag {
        width: 45%;
        margin: 5px;
    }

    .file-uploader-container {
        width: 45%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }

    .btn-send{
        margin-right: 15px;
    }

}

@media (min-width: 900px) {
    .form-home {
        width: 80%;
        margin: auto;
        padding: 10px;
        margin-top: 80px;
    }

    .form-home h1 {
        font-weight: 390;
        padding: 20px 0 20px 5px;
        font-size: 24px;
        letter-spacing: 2px;
    }

    .box-form {
        margin-top: 25px;
    }

    .txt-form {
        width: 32%;
        margin: 5px;
    }
    .txt-form-drag {
        width: 32%;
        margin: 5px;
       
    }

    .file-uploader-container {
        width: 32%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }
 
}

@media (min-width: 1400px) {
    .btn-send{
        margin-right: 45px;
    }
}