@media (max-width: 600px) {
    .carousel-container {
        width: 100%;
        max-width: 400px;
        overflow-x: auto;
        white-space: nowrap; 
        display: flex;
        justify-content: center;
        padding: 5px 0;
        scroll-behavior: smooth; 
    }
    
    .carousel-track {
        display: flex;
        gap: 3px;
        padding-left: 230%;
    }

    .carousel-item {
        display: inline-block;
        flex: 0 0 auto;
        width: 150px; 
    }

}

@media (min-width: 601px) and (max-width: 1000px) {
    .carousel-container {
        width: 100%;
        max-width: 600px; 
        overflow-x: auto; 
        white-space: nowrap; 
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        scroll-behavior: smooth; 
    }
    
    .carousel-track {
        display: flex;
        gap: 1rem;
        padding-left: 100%; 
    }
    
    .carousel-item {
        display: inline-block;
        flex: 0 0 auto;
        width: 150px;
    }
}


