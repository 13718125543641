/* CustomLoading.css */
.custom-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    /* background: #85287e; */
  }
  
  .armed {
    display: flex;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: bolder;
  }
  
  .armed span {
    opacity: 0;
    animation: appear 1s ease-in-out infinite;
  }
  
  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    25% {
      opacity: 1;
      transform: translateY(0);
    }
    75% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  