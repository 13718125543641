* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "inter", sans-serif;
  /* color: #fff; */
}

/* Seletor para o corpo do CKEditor */
/* Caso esteja usando o CKEditor 5, pode ser necessário ajustar o seletor */
.ck-editor__editable {
  min-height: 200px;
  /* Substitua 200px pela altura mínima desejada */
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #85287e;
}



/* FICHA DE CADASTRAMENTO */

.container {
  margin-top: 20px;
  border: 2px solid black;
}

.container strong {
  padding: 10px;
}

.tabela {
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
}

.celulaCabecalho {
  border: 1px solid black;
  width: 150px;
  padding: 3px;
}

.celulaCabecalhoDoc {
  border: 1px solid black;
  padding: 3px;
}
/* .celulaCabecalhoDoc label {
font-size: 8px;
} */

.celulaDado {
  border: 1px solid black;
  padding: 3px;
  font-weight: bold;
}