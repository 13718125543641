.home-artigo {
  width: 100%;
  height: 100Vh;
  background: #fff;
}


.home p {
  margin-top: 50px;
  font-weight: 300;
  font-size: 15pt;
}

.filtro-section {
  background: #f0f0f0;
  padding: 20px;
  margin-top: 20px;
}

.fullWidth {
  width: 100%;
}

.txtTema,
.txtTipo {
  width: 100%;
}

.tipo {
  margin-top: 15px;
}

.txt {
  background: #fff;
}

.anoTxt {
  width: 60%;
}

.escriTxt {
  width: 60%;
}

.pdf-articles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  margin-top: 30px;
  justify-content: center;
}

.pdf-article-card {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.pdf-article-card:hover {
  cursor: pointer;
  background: #e5e2e2;
}

.pdf-article-image svg {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  border-radius: 2px;
  overflow: hidden;
  color: #0078D4;
}

.pdf-article-image svg {
  width: 100%;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background: #cccccc;
}

.pdf-article-info {
  width: 100%;
}

.pdf-article-date {
  font-weight: 300;
  color: rgb(77, 77, 77);
  margin-bottom: 8px;
}

.pdf-article-title {
  font-size: 1em;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}

.pdf-article-links {
  display: flex;
  font-size: 14pt;
  gap: 15px;
}

.pdf-article-download,
.pdf-article-read-more {
  font-size: 0.85em;
  color: #0078D4;
  text-decoration: none;
}

.pdf-article-download:hover,
.pdf-article-read-more:hover {
  text-decoration: none;
  cursor: pointer;
  color: #5799dc;
}

.pdf-article-detele{
  font-size: 0.85em;
  color: #e72424;
  text-decoration: none;
  font-weight: 450;
}

.article-details {
  background: #fff;
  width: 100%;
  margin: auto;
  margin-top: 15vh;
}

.article-details-overview {
  font-weight: 400;
  color: rgb(47, 46, 46);
  margin-bottom: 8px;
  padding: 0 30px;
}

.article-details h3 {
  margin-top: 50px;
  font-size: 18pt;
  color: rgb(77, 77, 77);
  margin-bottom: 17px;
  padding: 0 30px;
}

.article-details-date {
  display: flex;
  gap: 10px;
  font-weight: 300;
  color: rgb(77, 77, 77);
  font-size: 15px;
  margin-bottom: 25px;
  padding: 0 20px;
}

.article-details-view {
  width: 100%;
  border-top: 1px solid #0c4c8c;
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  gap: 35px;
}

.img {
  width: 100%;
}


.baixar-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: #0078D4;
  border-radius: 4px;
  color: #fff;
  border: 0;
  font-weight: 400;
  cursor: pointer;
}

.article-details-allView {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: justify;
  margin-top: 5px;
}

.article-details-allView h4 {
  padding: 10px 0;
  font-weight: 600;
  font-size: 17pt;
}

.article-details-allView p {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1px;
}

.article-details-allView-right {
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
}

.article-details-allView-right h5 {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0;
  text-transform: uppercase;
  color: #00205D;
}

.article-details-allView-right p {
  font-size: 14px;
  font-weight: 390;
  letter-spacing: 1px;
}


@media (min-width: 700px) {
  .article-details {
    background: #fff;
    width: 100%;
    margin: auto;
    margin-top: 15vh;
  }

  .article-details-overview {
    font-weight: 400;
    color: rgb(47, 46, 46);
    margin-bottom: 8px;
    padding: 0 30px;
  }

  .article-details h3 {
    margin-top: 50px;
    font-size: 18pt;
    color: rgb(77, 77, 77);
    margin-bottom: 17px;
    padding: 0 30px;
  }

  .article-details-date {
    display: flex;
    gap: 10px;
    font-weight: 300;
    color: rgb(77, 77, 77);
    font-size: 15px;
    margin-bottom: 25px;
    padding: 0 20px;
  }

  .article-details-view {
    width: 100%;
    border-top: 1px solid #0c4c8c;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    gap: 35px;
  }

  .img {
    width: 30%;
  }

  .baixar-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #0078D4;
    border-radius: 4px;
    color: #fff;
    border: 0;
    font-weight: 400;
    cursor: pointer;
  }

  .article-details-allView {
    display: flex;
    flex-direction: column;
    width: 60%;
    text-align: justify;
    margin-top: 30px;
  }

  .article-details-allView h4 {
    padding: 10px 0;
    font-weight: 600;
    font-size: 17pt;
  }

  .article-details-allView p {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .article-details-allView-right {
    margin-top: 35px;
    width: 30%;
  }

  .article-details-allView-right h5 {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
    text-transform: uppercase;
    color: #00205D;
  }

  .article-details-allView-right p {
    font-size: 14px;
    font-weight: 390;
    letter-spacing: 1px;
  }

  .home-artigo {
    width: 80%;
    height: 100Vh;
    margin: auto;
    background: #fff;
  }

  .home {
    width: 100%;
    margin: auto;
    margin-top: 90px;
  }

  .home h1 {
    font-size: 17pt;
    letter-spacing: 2px;
    font-weight: 350;
    padding: 0;
    margin-left: 0;
  }

  .home p {
    margin-top: 50px;
    font-weight: 300;
    font-size: 15pt;
  }

  .filtro-section {
    background: #f0f0f0;
    padding: 20px;
    margin-top: 20px;
  }

  .fullWidth {
    width: 100%;
  }

  .txtTema,
  .txtTipo {
    width: 49.4%;
  }

  .tipo {
    margin-left: 10px;
  }

  .txt {
    background: #fff;
  }

  .anoTxt {
    width: 21%;
  }

  .escriTxt {
    width: 49%;
  }

  .pdf-articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-top: 30px;
    justify-content: flex-start;
  }

  .pdf-article-card {
    background: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 260px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .pdf-article-card:hover {
    cursor: pointer;
  }

  .pdf-article-image svg {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    border-radius: 2px;
    overflow: hidden;
  }

  .pdf-article-image svg {
    max-width: 100%;
    max-height: 100%;
  }

  .placeholder-image {
    width: 100%;
    height: 100%;
    background: #cccccc;
  }

  .pdf-article-info {
    width: 100%;
  }

  .pdf-article-date {
    font-weight: 300;
    color: rgb(77, 77, 77);
    margin-bottom: 8px;
  }

  .pdf-article-title {
    font-size: 1em;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
  }

  .pdf-article-links {
    display: flex;
    font-size: 14pt;
    gap: 15px;
  }

  .pdf-article-download,
  .pdf-article-read-more {
    font-size: 0.85em;
    color: #0078D4;
    text-decoration: none;
  }

  .pdf-article-download:hover,
  .pdf-article-read-more:hover {
    text-decoration: none;

  }

  .tipoField{
    margin-left:0;
  }
  

}

@media (min-width: 900px) {
  .home-artigo {
    width: 78%;
    height: 100Vh;
    margin: auto;
    background: #fff;
  }

  .home {
    width: 100%;
    margin: auto;
    margin-top: 90px;
  }

  .home h1 {
    font-size: 20pt;
    letter-spacing: 2px;
    font-weight: 350;
  }

  .home p {
    margin-top: 50px;
    font-weight: 300;
    font-size: 15pt;
  }

  .filtro-section {
    background: #f0f0f0;
    padding: 20px;
    margin-top: 20px;
  }

  .fullWidth {
    width: 100%;
  }

  .txtTema,
  .txtTipo {
    width: 49.6%;
  }

  .txt {
    background: #fff;
  }

  .anoTxt {
    width: 21%;
  }

  .escriTxt {
    width: 49%;
  }

  .pdf-articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-top: 30px;
    justify-content: flex-start;
  }

  .pdf-article-card {
    background: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 240px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .pdf-article-card:hover {
    cursor: pointer;
  }

  .pdf-article-image svg {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    border-radius: 2px;
    overflow: hidden;
  }

  .pdf-article-image svg {
    width: 100%;
    max-height: 100%;
  }

  .placeholder-image {
    width: 100%;
    height: 100%;
    background: #cccccc;
  }

  .pdf-article-info {
    width: 100%;
  }

  .pdf-article-date {
    font-weight: 300;
    color: rgb(77, 77, 77);
    margin-bottom: 8px;
  }

  .pdf-article-title {
    font-size: 1em;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
  }

  .pdf-article-links {
    display: flex;
    font-size: 14pt;
    gap: 15px;
  }

  .pdf-article-download,
  .pdf-article-read-more {
    font-size: 0.85em;
    color: #0078D4;
    text-decoration: none;
  }

  .pdf-article-download:hover,
  .pdf-article-read-more:hover {
    text-decoration: none;
  }

}


@media (min-width:1000px) {
  .article-details {
    background: #fff;
    width: 1250px;
    margin: auto;
    margin-top: 15vh;
  }

  .article-details-overview {
    font-weight: 400;
    color: rgb(47, 46, 46);
    margin-bottom: 8px;
    padding: 0 30px;
  }

  .article-details h3 {
    margin-top: 50px;
    font-size: 18pt;
    color: rgb(77, 77, 77);
    margin-bottom: 17px;
    padding: 0 30px;
  }

  .article-details-date {
    display: flex;
    gap: 10px;
    font-weight: 300;
    color: rgb(77, 77, 77);
    font-size: 15px;
    margin-bottom: 25px;
    padding: 0 20px;
  }

  .article-details-view {
    width: 100%;
    border-top: 1px solid #0c4c8c;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    gap: 35px;
  }

  .img {
    width: 20%;
  }

  .baixar-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #0078D4;
    border-radius: 4px;
    color: #fff;
    border: 0;
    font-weight: 400;
    cursor: pointer;
  }

  .article-details-allView {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: justify;
    margin-top: 30px;
  }

  .article-details-allView h4 {
    padding: 10px 0;
    font-weight: 600;
    font-size: 17pt;
  }

  .article-details-allView p {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .article-details-allView-right {
    margin-top: 35px;
    width: 20%;
  }

  .article-details-allView-right h5 {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
    text-transform: uppercase;
    color: #00205D;
  }

  .article-details-allView-right p {
    font-size: 14px;
    font-weight: 390;
    letter-spacing: 1px;
  }

}